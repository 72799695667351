import Swiper from 'swiper/bundle';
import 'bootstrap';

const [d] = [document];

class Blocks {
  mainvisual() {
    //メインスライダー
    new Swiper('.p-home__mainvisual-slider[data-plugin="mainSlider"]', {
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false,
      },
    });
  }
  carousel()
  {
    new bootstrap.Carousel('.carousel');
  }
}
async function instaAPI() {
  // 子要素<ul>を追加
  // document.querySelector('#insta').insertAdjacentHTML('beforeend', '<ul></ul>');

  let cards = 7; // insta投稿の表示件数を指定
  const response = await fetch(
    `https://graph.facebook.com/v19.0/17841457639834152?fields=name,media.limit(${cards}){ caption,media_url,thumbnail_url,permalink,like_count,comments_count,media_type}&access_token=EAAPn7Ur3ZAFMBO2O8D4LHMkmKYFbAbdNFVvZAacKqYADtyKbWBlejursO3GgMf5gxngcX5rZAWw3vduxafUmSNdx5atOd8qKWaOoZCfFupBZAblNaZA6wWwsoYdzhnk9jrg7FnFxZAyQnnjQ6dLkHb0grZC3hluuXUy718GI5ZBtmuEt4ozhcd9ea0QHwud10M1ZBKf5jk4FjDcMSFPCPx`
  );

  if (response.status === 200) {
    const resObjects = await response.json();
    // console.log(resObjects.media);
    //（挙動への影響は一切無いものの）オブジェクト{resObjects.media}内のプロパティ{paging}のせいで「instaItems[1]が無いというエラー」が出るので削除して以降の処理を進めていく
    delete resObjects.media.paging;

    Object.entries(resObjects.media).forEach((instaItems) => {
      // console.log(instaItems);
      instaItems[1].forEach((eachItem) => {
        if (eachItem.media_url !== null) {
          // 投稿が動画か否かを判定して{media}を変更
          if (eachItem.media_type === 'VIDEO') {
            eachItem.media = eachItem.thumbnail_url;
          } else {
            eachItem.media = eachItem.media_url;
          }

          document
            .querySelector('#insta')
            .insertAdjacentHTML(
              'beforeend',
              `<div class="instafeed__item"><a href="${eachItem.permalink}" target="_blank" rel="noopener"><img src="${eachItem.media}"></a></div>`
            );
        }
      });
    });
  } else {
    document
      .querySelector('#insta')
      .insertAdjacentHTML(
        'beforeend',
        `<p style="text-align:center;width:100%;">読み込めませんでした</p>`
      );
  }
}
const domReady = () => {
  let blocks = new Blocks();
  window.addEventListener('load', () => {
    blocks.mainvisual();
    blocks.carousel();
  });

  instaAPI(); // 関数の実行
};

export default function HOME() {
  d.addEventListener('DOMContentLoaded', domReady());
}
